<template>
    <div v-if="word" class="foot">
        <div class="foot-info">
            <div class="foot-left">
                <div>
                    <div class="foot-title">{{word.foot.product.title[language]}}</div>
                    <div @click="toProduct">{{word.foot.product.product1[language]}}</div>
                </div>
                <div>
                    <div class="foot-title">{{word.foot.developer.title[language]}}</div>
                    <div @click="toDoc">{{word.foot.developer.developer1[language]}}</div>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div>Copyright © 2016-2022 YONI-T.</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'foot',
    props: {
        reload: Boolean,
        word: Object
    },
    data() {
        return {
            language: null
        }
    },
    created() {
        this.language = localStorage.getItem('language')
    },
    watch: {
        reload:function () {
            this.language = localStorage.getItem('language')
        }
    },
    methods: {
        toDoc() {
            this.$router.push({path: '/docment'})
        },
        toProduct(e) {
            this.$router.push({path: '/product'})
        }
    }

}
</script>

<style lang="scss" scoped>
    .foot {
        background-color: #0f557b;
        color: #eee;
        .foot-info {
            padding-left: 5%;
            display: flex;
            align-items: center;
            margin-bottom: 50px;
            .foot-left {
                width: 60%;
                display: flex;
                height: 200px;
                >div {
                    margin: 20px;
                    width: 100%;
                    >div {
                        margin: 20px 0;
                        font-size: 14px;
                        cursor: pointer;
                    }
                }
                .about {
                    max-width: 280px;
                    width: 50%;
                    >div {
                        cursor: text;
                    }
                    a {
                        color: #eee!important;
                    }
                }
                .foot-title {
                    cursor: text;
                    font-size: 18px;
                    color: #cccccc;
                    font-weight: 600;
                    margin-bottom: 28px;
                }
            }
        }
        .copyright {
            width: 90%;
            margin: 0 auto;
            padding: 20px 0;
            border-top: 1px solid #999;
            text-align: center;
            font-size: 13px;
            div {
                margin-top: 5px;
            }
        }
    }
</style>
