<template>
    <div v-if="word && language">
        <div class="banner">
            <div class="banner-menu" :class="{'scroll' : scrollTop > 10}">
                <router-link to="/"><img class="logo" src="./../assets/logo.png" alt=""></router-link>
                <div class="menu">
                    <div v-for="item in menu" :key="item" class="menu-item" :class="{ 'active' : item !=  'home' ? url == '/' + item : url == '/' }">
                        <router-link :to="item !=  'home' ? '/' + item : '/'">{{word && word.menu ? word.menu[item][language] : ''}}</router-link>
                    </div>
                    <div class="menu-item login-btn" @click="toLogin">{{word && word.menu ? word.menu.login[language] : ''}}</div>
                    <div class="menu-item login-btn sign-up" @click="toSignUp">{{word && word.menu ? word.menu.signUp[language] : ''}}</div>
<!--                     <div class="menu-item language">{{language == 'CN' ? '简体中文' : 'English' }}<i class="iconfont">&#xe642;</i>
                        <div class="language-select animate__animated animate__fadeInUp">
                            <div class="triangle-up"></div>
                            <div class="triangle-up-shadow"></div>
                            <div class="language-select-item">
                                <div @click="chooseLanguage('CN')" :class="language == 'CN' ? 'active' : ''">简体中文</div>
                                <div @click="chooseLanguage('EN')" :class="language == 'EN' ? 'active' : ''">English</div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div v-if="!showBannerText" class="banner-img">
            <img @load="imgLoad" :src="require('./../assets/banner1' + language + '.png')" alt="">
        </div>
        <div v-if="showBannerText" class="banner-img">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in imgList" :key="item">
                        <div><img :src="require('./../assets/banner' + item + language + '.png')" alt=""></div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>
</template>

<!-- eslint-disable -->
<script>
import 'animate.css';

export default {
    name: 'banner',
    props: {
        word: Object,
    },
    data() {
        return {
            menu: ['home', 'product', 'price', 'docment', 'about'],
            showBannerText: false,
            scrollTop: 0,
            language: '',
            imgList: [
                1,2,3
            ],
            url: ''
        }
    },
    created() {
        this.url = this.$route.href
        this.language = localStorage.getItem('language')
    },
    mounted() {
        window.addEventListener('scroll', () => {
            this.scrollTop = document.documentElement.scrollTop
        })
        setTimeout(()=>{
            var mySwiper = new Swiper ('.swiper-container', {
                loop: true, // 循环模式选项
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                observer:true,
                observeParents:true,
                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                }
            })
        },1000)
    },
    watch: {
        '$route':function (val) {
            this.url = val.href
        }
    },
    methods: {
        imgLoad() {
            this.showBannerText = true;
            this.$emit('load', '')
        },
        chooseLanguage(e) {
            this.language = e;
            document.documentElement.scrollTop = 0;
            document.title = e == "CN" ? '优尼科技-全球短信/语音，更快速，更专业, 直连运营商，覆盖全球国家' : 'YoniTech-Global SMS/Voice, faster and more professional, directly connected to operators, covering countries around the world'
            localStorage.setItem('language', e);
            this.$emit('change', '');
        },
        toLogin() {
            window.open('http://sgzz.yoni-tech.com/#/login')
        },
        toSignUp() {
            window.open('http://sgzz.yoni-tech.com/#/register')
        }
    }

}
</script>

<style lang="scss" scoped>
    .language {
        position: relative;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            font-size: 12px;
            margin-left: 2px;
            line-height: 1;
        }
        &:hover {
            i {
                transform:rotate(180deg);
            }
            .language-select {
                display: block;
            }
        }
        .language-select {
            display: none;
            padding-top: 5%;
            position: absolute;
            top: 70%;
            left: 0;
            width: 100%;
            &:hover {
                display: block;
            }
            .triangle-up {
                z-index: 202;
                position: absolute;
                top: calc(110% - 8px);
                left: 50%;
                margin-left: -5px;
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 10px solid #ffffff;
            }
            .triangle-up-shadow {
                z-index: 199;
                position: absolute;
                top: calc(110% - 11px);
                left: 50%;
                margin-left: -7px;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 12px solid rgba(0,0,0,.02);
            }
            .language-select-item {
                z-index: 200;
                position: absolute;
                top: 110%;
                left: 0;
                width: 100%;
                height: 100px;
                background-color: #fff;
                text-align: center;
                border-radius: 8px;
                padding: 10px 0;
                box-shadow: 0 0 12px 1px rgba(0,0,0,.2);
                >div {
                    height: 50px;
                    line-height: 50px;
                    &:hover {
                        background-color: #E2EFF7;
                    }
                }
                .active {
                    background-color: #e2e2e2;
                }
            }
        }
    }
    .banner {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        .banner-menu {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 5%;
            height: 100px;
            width: 90%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.scroll {
                height: 80px;
                background-color: #fff;
                box-shadow: 0 0 12px 1px rgba(0,0,0,.1);
                .logo {
                  height: 50px;
                }
                .menu {
                    .menu-item {
                        line-height: 80px;
                        &.active {
                            border-bottom: 5px solid #108CCF;
                        }
                        &.login-btn {
                            line-height: 38px;
                        }
                    }
                }
            }
            .logo {
              height: 60px;
            }
            .menu {
                display: flex;
                height: calc(100% - 5px);
                align-items: center;
                .menu-item {
                    height: 100%;
                    line-height: 100px;
                    padding: 0 18px;
                    cursor: pointer;
                    border-bottom: 5px solid transparent;
                    a {
                        color: rgba(4,18,61,.7);
                    }
                    &.active {
                        a {
                            color: #108CCF;
                        }
                    }
                    a:hover {
                        color: #118CCF;
                    }
                }
                .login-btn {
                    border: 1px solid #393939;
                    border-radius: 30px;
                    padding: 0 20px;
                    margin: 0 16px;
                    height: 38px;
                    line-height: 38px;
                    &:hover {
                        color: #118CCF;
                        border-color: #118CCF;
                    }
                    &.sign-up {
                        border: 0;
                        padding-left: 0;
                    }
                }
                .language {
                    border-bottom: 0;
                }
            }
        }
    }
    .banner-img {
        width: 100%;
        display: block;
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
            display: block;
        }
        .banner-text {
            position: absolute;
            top: 35%;
            right: 0;
            width: 70%;
            text-align: center;
            font-size: 32px;
            .big {
                font-size: 48px;
                margin-bottom: 2%;
            }
        }
        .swiper-pagination {
            bottom: 10px;
            left: 0;
        }
    }
</style>
