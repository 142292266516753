<template>
    <div v-if="word" class="animate__animated animate__fadeInUp">
        <div v-if="!mode" ref="reliable" class="reliable">
            <div class="title">{{word.reliable.title[language]}}</div>
            <div>
                <div class="reliable-item right">   
                    <div class="reliable-text animate__animated" :class="scroll.reliable > 0 ? 'animate__backInUp' : ''">
                        <div class="reliable-title">{{word.reliable.monthly1[language]}}</div>
                        <div>{{word.reliable.monthly2[language]}}</div>
                        <div class="reliable-sub">{{word.reliable.monthly3[language]}}</div>
                    </div>
                    <div class="reliable-item-img">
                        <img src="./../assets/reliable-1.png" alt="">
                    </div>
                </div>
                <div class="reliable-item">
                    <div class="reliable-item-img">
                        <img src="./../assets/reliable-2.png" alt="">
                    </div>
                    <div class="reliable-text animate__animated" :class="scroll.reliable > 1 ? 'animate__backInUp' : ''">
                        <div class="reliable-title">{{word.reliable.rate1[language]}}</div>
                        <div>99%</div>
                        <div class="reliable-sub">{{word.reliable.rate2[language]}}</div>
                    </div>
                </div>
                <div class="reliable-item right">
                    <div class="reliable-text animate__animated" :class="scroll.reliable > 2 ? 'animate__backInUp' : ''">
                        <div class="reliable-title">{{word.reliable.countries1[language]}}</div>
                        <div>{{word.reliable.countries2[language]}}</div>
                        <div class="reliable-sub">{{word.reliable.countries3[language]}}</div>
                    </div>
                    <div class="reliable-item-img">
                        <img src="./../assets/reliable-3.png" alt="">
                    </div>
                </div>
                <div class="reliable-item">
                    <div class="reliable-item-img">
                        <img src="./../assets/reliable-4.png" alt="">
                    </div>
                    <div class="reliable-text animate__animated" :class="scroll.reliable > 3 ? 'animate__backInUp' : ''">
                        <div class="reliable-title">{{word.reliable.time1[language]}}</div>
                        <div>{{word.reliable.time2[language]}}</div>
                        <div class="reliable-sub">{{word.reliable.time3[language]}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!mode" class="special">
            <div class="title">{{word.special.title[language]}}</div>
            <div class="special-point" :class="language == 'EN' ? 'english' : ''">
                <div class="special-item animate__animated" :class="scroll.special ? 'animate__flipInY' : ''">
                    <div class="special-icon"><i class="iconfont">&#xe68d;</i></div>
                    <div class="special-item-title">{{word.special.special11[language]}}</div>
                    <div>{{word.special.special12[language]}}</div>
                </div>
                <div class="special-item animate__animated" :class="scroll.special ? 'animate__flipInY' : ''">
                    <div class="special-icon"><i class="iconfont">&#xeb66;</i></div>
                    <div class="special-item-title">{{word.special.special21[language]}}</div>
                    <div>{{word.special.special22[language]}}</div>
                </div>
                <div class="special-item animate__animated" :class="scroll.special ? 'animate__flipInY' : ''">
                    <div class="special-icon"><i class="iconfont">&#xe6ad;</i></div>
                    <div class="special-item-title">{{word.special.special31[language]}}</div>
                    <div>{{word.special.special32[language]}}</div>
                </div>
                <div class="special-item animate__animated" :class="scroll.special ? 'animate__flipInY' : ''">
                    <div class="special-icon"><i class="iconfont">&#xe636;</i></div>
                    <div class="special-item-title">{{word.special.special41[language]}}</div>
                    <div>{{word.special.special42[language]}}</div>
                </div>
                <div class="special-item animate__animated" :class="scroll.special ? 'animate__flipInY' : ''">
                    <div class="special-icon"><i class="iconfont">&#xe603;</i></div>
                    <div class="special-item-title">{{word.special.special51[language]}}</div>
                    <div>{{word.special.special52[language]}}</div>
                </div>
                <div class="special-item animate__animated" :class="scroll.special ? 'animate__flipInY' : ''">
                    <div class="special-icon"><i class="iconfont">&#xe694;</i></div>
                    <div class="special-item-title">{{word.special.special61[language]}}</div>
                    <div>{{word.special.special62[language]}}</div>
                </div>
            </div>
        </div>
        <div :class="{'alone' : mode}" class="form">
            <div class="form-left">{{word.foot.form.tips[language]}}</div>
            <div class="form-body">
                <div class="form-input">
                    <textarea :placeholder="word.foot.form.tips[language]" rows="4" cols="20" style="min-height: 33px;" v-model="form.content"></textarea>
                </div>
                <div class="form-input">
                    <input type="text" :placeholder="word.foot.form.name[language]" autocomplete="off" class="el-input__inner" v-model="form.name">
                </div>
                <div class="form-input">
                    <input type="text" :placeholder="word.foot.form.phone[language]" autocomplete="off" class="el-input__inner" v-model="form.phone">
                </div>
                <div class="form-input">
                    <input type="tel" :placeholder="word.foot.form.email[language]" autocomplete="off" class="el-input__inner" v-model="form.email">
                </div>
                <button class="form-button" :class="{'disable' : sec > 0}" @click="toSubmit">{{sec > 0 ? language == "EN" ? word.foot.form.countdown[language] + " " + sec + " " + word.foot.form.sec[language] : sec + word.foot.form.sec[language] + word.foot.form.countdown[language] : word.foot.form.buttonText[language]}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import 'animate.css';
import axios from '@/utils/request/request.js'
import base from '@/utils/request/base.js'
import word from './../assets/json/language.json'

export default {
    name: 'Home',
    components: {},
      props: {
        mode: {
          type: Boolean,
          default: false
        }
      },
    data() {
        return {
            sec: 0,
            language: '',
            word: word,
            scrollTop: 0,
            defaultHeight: 900,
            scroll: {
                reliable: 0,
                special: false,
            },
            form: {
                name: '',
                phone: '',
                email: '',
                content: '',
            }
        }
    },
    created() {
        this.sec = localStorage.getItem('sec')
        if(this.sec > 0) {
            let countdown = setInterval(()=>{
                localStorage.setItem('sec', this.sec);
                this.sec--;
                if(this.sec == 0) {
                    localStorage.removeItem('sec');
                    clearInterval(countdown)
                }
            }, 1000)
        }
        this.language = localStorage.getItem('language')
        this.defaultHeight = 700 * window.innerWidth / 1920 - window.innerHeight / 2;
    },
    mounted() {
        if(this.mode) {
            document.querySelector("meta[name=viewport]").content = 'width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no'
        }
        window.addEventListener('scroll', () => {
            this.scrollTop = document.documentElement.scrollTop > this.scrollTop ? document.documentElement.scrollTop : this.scrollTop
            this.scroll.reliable = Math.floor((this.scrollTop - this.defaultHeight) / 270) + 1;
            this.scroll.special = this.scroll.reliable > 3 && this.scrollTop > 1680 + this.defaultHeight
        })
    },
    methods: {
        toSubmit() {
            if(this.sec > 0) {
                return false;
            }
            if(!this.form.content) {
                let message = this.language == 'CN' ? '请填写内容' : 'Please fill in the content'
                this.$emit('msg', message, false)
            }else if(!this.form.name) {
                let message = this.language == 'CN' ? '请填写您的姓名' : 'Please fill in your name'
                this.$emit('msg', message, false)
            }else if(!this.form.phone && !this.form.email) {
                let message = this.language == 'CN' ? '请填写您的联系方式' : 'Please fill in your contact information'
                this.$emit('msg', message, false)
            }else {
                this.sec = 10;
                let countdown = setInterval(()=>{
                    localStorage.setItem('sec', this.sec);
                    this.sec--;
                    if(this.sec == 0) {
                        localStorage.removeItem('sec');
                        clearInterval(countdown)
                    }
                }, 1000)
                axios.post(`leave-message/add`, {
                    name: this.form.name,
                    phone: this.form.phone,
                    email: this.form.email,
                    content: this.form.content,
                }).then(res=>{
                    if(res.data == '提交成功') {
                        this.form.name = '';
                        this.form.phone = '';
                        this.form.email = '';
                        this.form.content = '';
                        this.$emit('msg', this.word.foot.form.success[this.language], true)
                    }else {
                        this.$emit('msg', this.word.foot.form.error[this.language], false)
                    }
                }).catch((res=>{
                    let msg = this.language == 'EN' ? 'Submit Error' : '提交失败';
                    this.$emit('msg', msg, false)
                }))
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 46px;
    height: 200px;
    line-height: 200px;
    text-align: center;
}
.reliable {
    .reliable-item {
        padding: 50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #659680;
        color: #f7f7f7;
        &.right {
            background-color: #fff;
            color: #353535;
            .reliable-text {
                margin-left: 0;
                margin-right: 100px;
                text-align: right;
                .reliable-sub {
                    color: #787878;
                }
            }
        }
        .reliable-text {
            width: 400px;
            text-align: left;
            margin-left: 100px;
            margin-bottom: 50px;
            font-size: 26px;
            .reliable-title {
                font-size: 34px;
                margin-bottom: 10px;
            }
            .reliable-sub {
                margin-top: 20px;
                font-size: 18px;
                color: #e2e2e2;
            }
        }
        .reliable-item-img {
            height: 270px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 300px;
            img {
                width: 100%;
            }
        }
    }
}
.special {
    background-color: #e2eff7;
    .special-point {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        &.english {
            .special-item {
                font-size: 13px;
                .special-item-title {
                    font-size: 16px;
                    margin: 14px 0;
                }
            }
        }
        .special-item {
            background-color: #fff;
            border-radius: 16px;
            padding: 16px;
            height: 250px;
            width: 25%; 
            margin: 0 1% 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            font-size: 14px;
            color: #2e2e2e;
            box-shadow: 0px 0px 5px rgba(0,0,0,.2);
            cursor: pointer;
            &:hover {
                transform:scale(1.05)!important;
                box-shadow: 0px 0px 10px rgba(0,0,0,.4);
                color: #108CCF;
            }
            .special-icon {
                padding-top: 20px;
                height: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                i {
                    font-size: 48px;
                }
            }
            .special-item-title {
                margin: 20px 0;
                font-size: 26px;
            }
        }
    }
}
.friend {
    width: 70%;
    margin: 0 auto;
    padding-bottom: 50px;
    .friend-list {
        margin-top: -30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .friend-item {
            margin: 12px 24px;
            width: 164px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
            border-radius: 16px;
            box-shadow: 0px 0px 5px rgba(0,0,0,.2);
            img {
                width: 144px;
            }
        }
    }
}
.form {
    background-color: #e2eff7;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    &.alone {
        overflow: hidden;
        padding: 0;
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        flex-direction: column;
        .form-left {
            width: 100%;
        }
        .form-body {
            width: 80%;
            margin: 50px auto 0;
            .form-button {
                width: 100%;
            }
        }
    }
    .form-left {
        width: 400px;
        font-size: 40px;
        text-align: center;
    }
    .form-body {
        width: 400px;
        input {
            width: 200px;
            -webkit-appearance: none;
            background-color: #fff;
            background-image: none;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
            box-sizing: border-box;
            color: #606266;
            display: inline-block;
            font-size: 16px;
            height: 40px;
            line-height: 40px;
            outline: none;
            padding: 0 15px;
            transition: border-color .2s cubic-bezier(.645,.045,.355,1);
            width: 100%;
        }
        textarea {
            display: block;
            resize: vertical;
            padding: 5px 15px;
            line-height: 1.5;
            box-sizing: border-box;
            width: 100%;
            font-size: 16px;
            color: #606266;
            background-color: #fff;
            background-image: none;
            border: 1px solid #dcdfe6;
            border-radius: 4px;
            transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        }
        .form-input {
            margin-bottom: 10px;
        }
        .form-button {
            display: inline-block;
            line-height: 1;
            white-space: nowrap;
            cursor: pointer;
            background: #108CCF;
            border: 1px solid #108CCF;
            color: #fff;
            -webkit-appearance: none;
            text-align: center;
            box-sizing: border-box;
            outline: none;
            margin: 0;
            transition: .1s;
            font-weight: 500;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            padding: 12px 0;
            width: 400px;
            font-size: 14px;
            border-radius: 4px;
            &.disable {
                color: #fff;
                background-color: #a0cfff;
                border-color: #a0cfff;
                cursor: not-allowed;
            }
        }
    }
}
</style>
